import axios from 'axios'
import _ from 'lodash'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import {AppConfig, PromoPeriod} from './config_d'

export namespace Config {
  const E_PAY_URL = 'https://epay.kkb.kz/jsp/process/logon.jsp' // - URL куда отправлять данные оплаты

  let config: AppConfig = {
    version: '1.0.0',
    symbolVersion: 'wooden block',
    release: '0',
    isInit: false,
    isAdmin: null,
    isDev: null,
    isTest: null,
    baseUrl: 'https://newdev.mybuh.kz',
    ePayUrl: E_PAY_URL,
    publicFolderUrl: process.env.PUBLIC_URL ?? '',
    promo: null,
  }

  // console.log(`V. ${config.version} "${config.symbolVersion}"; Release redesign ${config.release}`)

  export const getConfig = () => {
    return config
  }

  export const setConfig = (newConfig: Partial<AppConfig>) => {
    config = _.merge(config, newConfig)
  }

  export const init = async (devParams?: {isAdmin?: boolean}) => {
    // load plugins
    dayjs.extend(customParseFormat)
    dayjs.extend(duration)
    dayjs.extend(utc)
    dayjs.extend(timezone)

    const hostNames = [
      'tn1.kz',
      'test.tn1.kz',
      'cabinettest.mybuh.kz',
      'cabinet.mybuh.kz',
      'regi.tn1.kz',
      'regitest.tn1.kz',
      'newdev.mybuh.kz',
      'newregi.mybuh.kz',
      'v3dev.mybuh.kz',
    ]

    let baseUrl = `https://${window.location.hostname}`

    // Определяем на каком домене сервер. Если локалхост, то 'cabinetdev.mybuh.kz' или 'regidev.mybuh.kz'

    if (!hostNames.includes(window.location.hostname)) {
      // baseUrl = 'https://regidev.mybuh.kz'
      // baseUrl = 'https://newdev.mybuh.kz'
      baseUrl = 'https://test.tn1.kz'
      // baseUrl = 'https://cabinettest.mybuh.kz'
      // baseUrl = 'https://v3dev.mybuh.kz'
    }

    if (devParams?.isAdmin) {
      baseUrl = 'https://regitest.tn1.kz'
      // baseUrl = 'https://regitest.mybuh.kz'
    }

    const serverConfig: {
      IS_ADMIN: boolean
      IS_DEVELOPMENT: boolean
      IS_TEST: boolean
      promo: PromoPeriod
    } = await axios({
      method: 'POST',
      timeout: 0,
      url: baseUrl + '/ajax.php',
      withCredentials: true,
      headers: {'Content-type': 'application/x-www-form-urlencoded'},
      data:
        'params=' +
        encodeURIComponent(
          JSON.stringify({
            modul: 'app_config',
            oper: 'app_settings',
          })
        ),
    }).then(res => res.data.data)

    setConfig({
      isAdmin: devParams?.isAdmin ?? serverConfig.IS_ADMIN,
      isTest: serverConfig.IS_TEST,
      isDev: process.env.REACT_APP_BUILD_TYPE === 'development',
      baseUrl: baseUrl,
      isInit: true,
      promo: serverConfig.promo,
    })

    return true
  }
}
